footer{
    background: var(--color-primary);
    padding: 3rem 0;
    text-align: center;
    font-size: 1rem;
    }

footer a{
    color: var(--color-bg);

}

.footer_logo{
    font-size: 2rem;
    font-weight: 500;
    margin-bottom: 2rem;
    display: inline-block;
}

.footer_socials{
    display: flex;
    justify-content: center;
    gap: 1rem;
    margin-bottom: 2rem;
}

.footer_socials a{
    background: var(--color-bg);
    color: var(--coloer-white);
    padding: 0.8rem;
    border-radius: 0.7rem;
    display: flex;
    border: 1px solid transparent;
}

.footer_socials a:hover{
    background: transparent;
    color: var(--color-bg);
    border-color: var(--color-bg);



}
.footer_copyrights{
    margin-bottom: 4rem;
    color: var(--color-bg);
}

@media screen and (max-width:600px) {

    .footer_socials{
        margin-bottom: 2.6rem;
    }
}