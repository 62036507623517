
.experience_container{
    justify-content: center;


}
.icons{
    font-size: 2rem;
    font-weight: 500;
}

.experience_container{
    background: var(--color-bg-variant);
    padding: 2.4rem 5rem;
    border-radius: 2rem;
    border: 1px solid transparent;

}

.experience_container:hover{
    background: transparent;
    border-color: var(--color-primary-transparent);
    cursor: default;


}

.experience_container > h3{
    text-align: center;
    margin-bottom: 2rem;
    color: var(--color-primary);
}

.experience_content{
    display: grid;
    grid-template-columns: 1fr 1fr;
    row-gap: 2rem;
    column-gap: 8rem;

}

