.container.contact_container{
    width: 58%;
    display:  grid;
    grid-template-columns: 90%;
    gap: 12%;
}


form{
    display: flex;
    flex-direction:  column;
    gap: 1.2rem;
}

input,textarea{
    width:100%;
    padding: 1.5rem;
    border-radius: 0.5rem;
    background: transparent;
    border: 2px solid var(--color-primary-variant);
    resize: none;
    color: var(--coloer-white);
}