header{
    height: 100vh;
    padding-top: 4rem;
    overflow: hidden;
}

.header_container{
    text-align: center;
    height: 100%;
    position: relative;

}

.Cta{
    margin-top: 2rem;
    display: flex;
    gap: 1rem;
    justify-content: center;
    align-items: center;
    text-align: center;

}

.header_socials{
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 0.8rem;
    position: absolute;
    left: 0;
    bottom: 3rem;

}
.header_socials::after{
    content: "";
    height: 2rem;
    width: 1px;
    background: var(--color-primary);

}

.Me{
    background:linear-gradient( var(--color-primary),transparent);
    /* transform: translateX(21.5rem) translateY(1rem); */
    
    position: absolute;
    margin-top: 4rem;
    width: 22rem;
    height: 22rem;
    left: 50%;
    transform: translateX(-50%);
    border-radius:12rem 12rem 0 0  ;

}

.scroll_down{
    position: absolute;
    right: -2.3rem;
    bottom: 5rem;
    transform: rotate(90deg);
    font-weight: 200;
    font-size: 0.9rem;

}
@media screen and (max-width:1024px){
    header{
        height: 68vh;
    }
}

@media screen and (max-width:600px) {
    header{
        height: 100vh;
    }
    .header_socials,.scroll_down{
        display: none;
    }
}
